import axios from 'axios';
export async function getFavorites(page) {
    try {
        let response = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/pe/favorites?page=${page}`, {});
        return response.data;
    }
    catch (error) {
        return [];
    }
}

export async function deleteFavorite(candidateId) {
    try {
        await axios.delete(`${process.env.REACT_APP_BASE_API_URL}/api/pe/favorites/${candidateId}`, {});
        return true;
    }
    catch (error) {
        console.log(error); // TODO improve error handling
        return false;
    }
}


export async function addFavorite(candidateId) {
    try {
        await axios.post(`${process.env.REACT_APP_BASE_API_URL}/api/pe/favorites/${candidateId}`, {});
        return true;
    }
    catch (error) {
        console.log(error); // TODO improve error handling
        return false;
    }
}

