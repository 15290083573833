import React, { useState, useContext, useEffect } from "react";
import Select from "react-select";
import '../styles/Admin.css';

import FilterContext from "../context/filterContext";

import collegesOptionsDefault from "../lists/colleges.json";
import majorsOptionsDefault from "../lists/majors.json";

import { Modal, Box, Typography } from "@material-ui/core";
import Checkbox from '@mui/material/Checkbox';


function Filters({ applyFilter }) {

    /* context */

    const filterContext = useContext(FilterContext);

    /* states for modals */

    const [chevronAtivo, setChevronAtivo] = useState(false);
    const [modal, setModal] = useState(false);

    /* filter states */

    const [filterName, setFilterName] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [universities, setUniversities] = useState([]);
    const [majors, setMajors] = useState([]);
    const [linkedin, setLinkedin] = useState(false);
    const [cv, setCv] = useState(false);
    const [conference, setConference] = useState(false);

    const [majorsOptions, setMajorsOptions] = useState(majorsOptionsDefault);
    const [collegesOptions, setCollegesOptions] = useState(collegesOptionsDefault);

    useEffect(() => {
        if (filterContext.unis) {
            setCollegesOptions(filterContext.unis);
        }
        if (filterContext.majors) {
            setMajorsOptions(filterContext.majors);
        }
    }, [filterContext.unis, filterContext.majors]);

    const handleSave = async () => {

        if (!filterName) {
            alert("Please, enter a filter name");
            return;
        }

        const newBody = {
            firstName: name ? name : "",
            lastName: surname ? surname : "",
            email: email ? email : "",
            universities: universities ? universities : "",
            majors: majors ? majors : "",
            linkedin: linkedin ? linkedin : "",
            cv: cv ? cv : "",
            conference: conference ? conference : ""
        };

        console.log(newBody);

        let res;
        if (filterContext.getFilter(filterName)) {
            res = await filterContext.updateFilter(filterName, newBody);
            res = !res;

        } else {
            res = await filterContext.saveFilter(filterName, newBody)
        }

        if (!res) {
            alert("Error saving filter");

        } else {
            setModal(false);
        }
    }

    const handleApply = async () => {
        let query = "";

        if (name) {
            query += `firstName=${encodeURIComponent(name)}&`;
        }

        if (surname) {
            query += `lastName=${encodeURIComponent(surname)}&`;
        }

        if (email) {
            query += `email=${encodeURIComponent(email)}&`;
        }

        if (linkedin) {
            query += 'linkedin=l&';
        }

        if (cv) {
            query += 'cvUrl=c&';
        }

        if (conference) {
            query += 'conference=true&';
        }

        universities.forEach(university => {
            query += `university=${encodeURIComponent(university)}&`;
        })

        majors.forEach(major => {
            query += `major=${encodeURIComponent(major)}&`;
        })
        query = query.slice(0, query.length - 1);
        applyFilter(query);
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'min(80%, 400px)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '1rem',
    };

    const selectedMajors = majorsOptions.filter(m => majors.includes(m.value));
    const selectedUnis = collegesOptions.filter(u => universities.includes(u.value));

    return (
        <div>
            <Modal
                open={modal}
                onClose={() => setModal(false)}
            >
                <Box sx={style}>

                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Create a name for your filter:
                    </Typography>

                    <div>

                        <input
                            type="text"
                            className="form-control"
                            id="filterName"
                            placeholder="Filter name"
                            onChange={(e) => setFilterName(e.target.value)}
                            value={filterName}
                        />
                        <small
                            id="nomeHelp"
                            className="form-text text-muted"
                        >
                            At least 3 characters.
                        </small>

                    </div>

                    <button
                        sytle={{
                            marginTop: '100px'
                        }}
                        type="button"
                        className="btn btn-dark btn-lg btn-block"
                        onClick={handleSave}
                    >
                        {filterContext.getFilter(filterName) ? "UPDATE" : "SAVE"} FILTER
                    </button>

                </Box>

            </Modal>

            <button className="btn time-collpase-title bg-dark-grey border-0" aria-expanded="false" onClick={() => setChevronAtivo(!chevronAtivo)}>
                <span className="text-white">Filters</span>
                <i className={"fas " + (chevronAtivo ? "fa-chevron-up" : "fa-chevron-down")} /></button>
            <div className={"collapse " + (chevronAtivo ? 'show' : '')}>
                <div className="card card-body mb-4">

                    <div className="form-group">
                        <label htmlFor="university">Saved Filters</label>
                        <Select
                            isClearable={true}
                            name="Filters"
                            options={filterContext.filters
                                ? filterContext.filters.map(f => ({ label: f.name, value: f }))
                                : []}
                            className="basic-select"
                            classNamePrefix="select"
                            placeholder="Filter"
                            onChange={(e) => {

                                if (!e) {
                                    setFilterName("");
                                    setName("");
                                    setSurname("");
                                    setEmail("");
                                    setUniversities([]);
                                    setMajors([]);
                                    setLinkedin(false);
                                    setCv(false);
                                    setConference(false);
                                    return;
                                }

                                const filterObject = e.value;
                                setFilterName(filterObject.name ? filterObject.name : "");
                                setName(filterObject.filters.firstName ? filterObject.filters.firstName : "");
                                setSurname(filterObject.filters.lastName ? filterObject.filters.lastName : "");
                                setEmail(filterObject.filters.email ? filterObject.filters.email : "");
                                setUniversities(filterObject.filters.universities ? filterObject.filters.universities : []);
                                setMajors(filterObject.filters.majors ? filterObject.filters.majors : []);
                                setLinkedin(filterObject.filters.linkedin ? filterObject.filters.linkedin : false);
                                setCv(filterObject.filters.cv ? filterObject.filters.cv : false);
                                setConference(filterObject.filters.conference ? filterObject.filters.conference : false);
                            }}
                        />
                        <small id="universityHelp" className="form-text text-muted">You can select as many universities as you would like.</small>
                    </div>


                    <form>

                        <div className="form-group">
                            <label htmlFor="nomeOuSobrenome">First Name</label>
                            <input
                                aria-describedby="nomeHelp"
                                type="text"
                                className="form-control"
                                id="nomeOuSobrenome"
                                placeholder="First name or part of it"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <small id="nomeHelp" className="form-text text-muted">At least 3 characters.</small>
                        </div>

                        <div className="form-group">
                            <label htmlFor="nomeOuSobrenome">Last Name</label>
                            <input
                                aria-describedby="nomeHelp"
                                type="text"
                                className="form-control"
                                id="nomeOuSobrenome"
                                placeholder="Last name or part of it"
                                value={surname}
                                onChange={(e) => setSurname(e.target.value)}
                            />
                            <small id="nomeHelp" className="form-text text-muted">At least 3 characters.</small>
                        </div>

                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                aria-describedby="emailHelp"
                                type="text"
                                className="form-control"
                                id="email"
                                value={email}
                                placeholder="Email or part of it"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <small id="emailHelp" className="form-text text-muted">At least 3 characters.</small>
                        </div>

                        <div className="form-group">
                            <label htmlFor="university">University</label>
                            <Select
                                isMulti
                                name="Universities"
                                options={collegesOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Universities"
                                onChange={(e) => setUniversities(e.map((v) => v.value))}
                                value={selectedUnis}
                            />
                            <small id="universityHelp" className="form-text text-muted">You can select as many universities as you would like.</small>
                        </div>

                        <div className="form-group">
                            <label htmlFor="major">Major</label>
                            <Select
                                isMulti
                                name="Major"
                                options={majorsOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Majors"
                                onChange={(e) => setMajors(e.map((v) => v.value))}
                                value={selectedMajors}
                            />
                            <small id="majorHelp" className="form-text text-muted">You can select as many majors as you would like.</small>
                        </div>

                        <div className="form-row">

                            <div className="col-md-5">

                                <div style={{ flexDirection: 'row' }}>
                                    <Checkbox onClick={(e) => setCv(e.target.checked)} checked={cv} />
                                    <label>Only show candidates with CVs</label>
                                </div>
                                {/* <div style={{ flexDirection: 'row' }}>
                                    <Checkbox onClick={(e) => setConference(e.target.checked)} checked={conference} />
                                    <label>Only show candidates that are going to the conference</label>
                                </div> */}

                                <Checkbox onClick={(e) => setLinkedin(e.target.checked)} checked={linkedin} />
                                <label> Only show candidates with a LinkedIn profile</label>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="col-6 mt-3">
                                <button type="button" className="btn btn-dark btn-lg btn-block" onClick={handleApply}>APPLY FILTER</button>
                            </div>
                            <div className="col-6 mt-3">
                                <button type="button" className="btn btn-dark btn-lg btn-block" onClick={() => setModal(true)}>SAVE FILTER</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Filters;