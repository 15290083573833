import axios from 'axios';

export async function getCandidates(page, filterName = '', query = '') {
    try {
        let response = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/pe/candidates?page=${page}${query
            ? `&${query}`
            : ''}`,
            {},
            {
                headers: {
                    'name': filterName
                },
            }
        );

        return response.data;
    }
    catch (error) {
        console.log(error); // TODO improve error handling
        return false;
    }
}
