import React from "react";
import Login from "./components/pages/Login";
import Dashboard from "./components/pages/Dashboard";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HttpsRedirect from 'react-https-redirect';
import { ProtectedRoute } from "./ProtectedRoute";
import { AuthProvider } from "./services/authentication";
import { FilterContextProvider } from "./context/filterContext";


function App() {
    console.log(process.env.REACT_APP_CONF);

    return (
        <HttpsRedirect>
            <Router>
                <div>
                    <AuthProvider>
                        <FilterContextProvider>
                            <Switch>
                                <Route path="/" exact component={Login} />
                                <ProtectedRoute path="/dashboard" exact component={Dashboard} />
                                <ProtectedRoute path="/favorites" exact component={Dashboard} />
                            </Switch>
                        </FilterContextProvider>
                    </AuthProvider>
                </div>
            </Router>
        </HttpsRedirect>
    );
}

export default App;
