import axios from 'axios';

export async function getFilters() {
    let response = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/pe/filters`, {});
    return response.data;
}

export async function createFilter(token, filter) {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_API_URL}/api/pe/filters`,
            {
                name: filter.name,
                criteria: filter.criteria
            },
            {
                headers: {
                    'x-access-token': token,
                },
            },
        );

        return response.status;
    }
    catch (error) {
        console.log(error); // TODO improve error handling
        return false;
    }
}

export async function deleteFilter(token, filter) {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_BASE_API_URL}/api/pe/filters/${filter.name}`, {},
            {
                headers: {
                    'x-access-token': token,
                },
            },
        );

        return response.status;
    }
    catch (error) {
        console.log(error); // TODO improve error handling
        return false;
    }
}

export async function editFilter(token, filter) {
    try {
        const response = await axios.put(`${process.env.REACT_APP_BASE_API_URL}/api/pe/filters/${filter.name}`,
            {
                criteria: filter.criteria
            },
            {
                headers: {
                    'x-access-token': token,
                },
            },
        );

        return response.status;
    }
    catch (error) {
        console.log(error); // TODO improve error handling
        return false;
    }
}
